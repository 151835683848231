export const SITE_LOGO = require("../../webroot/images/carmudi-logo.png");
export const LOGIN_PAGE_LOGO = require("../../webroot/images/carmudi-logo-64.png");
export const TITLE = "Carmudi";
export const LANGUAGE = [
	{ value: 1, label: "EN", iso_code: "en" }
];
export const ISD_CODE = [
	{
		isdCode: "+63",
		isdCodeWithoutSign: "63",
		startsFrom: 8,
		minLength: 8,
		maxLength: 12,
	},
];
import secureStorage from "../encrypt";
export const API_URL = {
	GETWAY_API: process.env.REACT_APP_GETWAY_API,
	COMMON_GATEWAY_API: process.env.REACT_APP_GETWAY_API,
};
console.log(API_URL, "AAPPP")
export const SETTING = {
	CRYPTO_KEY: "U*oDwrIU!B#QX37%#24",
	RESEND_OTP_TIME_IN_SECOND: 30,
};
export const ADMIN_ROLE_ID = 1;
export const MAKER_ROLE_ID = 4;
export const APPROVER_ROLE_ID = 5;
export const TL_ROLE_ID = 4;
export const FINANCE_TEAM_ROLE_ID = 6;
export const OPS_TEAM_ROLE_ID = 6;
export const DATE_FORMAT = "dd mmm yyyy, HH:MM";
export const DATE_FORMAT_DMY = "dd mmm yyyy";
export const DATE_FORMAT_INPUT = "yyyy-MM-dd";
export const DATE_FORMAT_YMD = "yyyy-mm-dd";
export const DATE_FORMAT_MDY = "mmm dd, yyyy";
export const DATE_FORMAT_INPUT_TIME = "yyyy-MM-dd h:mm aa";
export const DATE_TIME_FORMAT = "yyyy-mm-dd HH:MM:ss";

export const DATE_FORMAT_FILTER = "yyyy-mm-dd";
export const BANK_LIST = [
	{ value: "Unionbank", label: "Unionbank", account_number: '000580021397', outbound_account_number: '000580021397', is_show_outbound: true, is_show_inbound: true },
	{ value: "Unionbank1", label: "Unionbank", account_number: '000580021385', outbound_account_number: '000580021385', is_show_outbound: true, is_show_inbound: false },
	{ value: "BDO", label: "BDO", account_number: '005688024258', outbound_account_number: '005688024258', is_show_outbound: true, is_show_inbound: false },
	{ value: "BDO1", label: "BDO", account_number: '005688024266', outbound_account_number: '005688024266', is_show_outbound: false, is_show_inbound: true },
	{ value: "BPI1", label: "BPI", account_number: '2841002094', outbound_account_number: '2841002094', is_show_outbound: true, is_show_inbound: false },
	{ value: "BPI2", label: "BPI", account_number: '2841002108', outbound_account_number: '2841002108', is_show_outbound: false, is_show_inbound: true },
	{ value: "BDO2", label: "BDO", account_number: '005688020872', outbound_account_number: '005688020872', is_show_outbound: false, is_show_inbound: true },
	{ value: "Unionbank2", label: "Unionbank", account_number: '000580022043', outbound_account_number: '000580022043', is_show_outbound: false, is_show_inbound: true },
	{ value: "Unionbank3", label: "Unionbank", account_number: '000580021440', outbound_account_number: '000580021440', is_show_outbound: true, is_show_inbound: false },

];
export const PAYMENT_TYPE = [
	{ value: "disbursal", label: "Disbursal" },
	{ value: "refund", label: "Refund" }
];
export const SPLIT_PAYMENT_TYPE = [
	{ value: "main_dealer", label: "Dealer Payment" },
	{ value: "split_dealer", label: "Split Payment" },
	{ value: "split_dealer2", label: "Split Payment2" },
	{ value: "agent_fee", label: "Agent Fee" },
	{ value: "referral_agent", label: "Referral Agent" },
	{ value: "agent_incentive", label: "Agent Incentive" },
	{ value: "onhold_dealer", label: "Onhold Dealer Payment" },
	{ value: "aro_fee", label: "ARO Fee" }
];
export const PAYMENT_ORDER = ['main_dealer', 'split_dealer', 'split_dealer2', 'referral_agent', 'agent_fee', 'agent_incentive'];
export const IS_NUMBER_VALIDATION = /^[0-9]*$/;

export const REQUEST_PAYMENT_TYPE = {
	"Normal": "Payment will be made to dealer once the amount is received from the Financier",
	"Ad-Hoc": "Payment need to be made to dealer within 1 hour of request generation",
	"Advance": "Payment need to be made to dealer by today only or in the next batch of payment requests",
	"SFAP": "Payment need to be made to dealer as soon as the request gets generated",
}
export const REQUEST_PAYMENT_TYPES = [
	{ value: "normal", label: "Normal" },
	{ value: "ad_hoc", label: "Ad-Hoc" },
	{ value: "advance", label: "Advance" }
]

export const PAYMENT_REQUEST_TYPE = [
	{ value: "Normal", label: "Normal" },
	{ value: "Ad-Hoc", label: "Ad Hoc" },
	{ value: "Advance", label: "Advance" },
	{ value: "SFAP", label: "SFAP" }
]
export const displayAmount = (amount) => {
	let amount2display = `PHP ${amount}`;
	return amount2display;
};

export const REPORT_DATE_TYPE = [
	{ value: 'weekly', label: 'Weekly' },
	{ value: 'monthly', label: 'Monthly' },
	{ value: 'quarterly', label: 'Quarterly' },
	{ value: 'half_yearly', label: 'Half Yearly' },
	{ value: 'custom_date', label: 'Custom Date' }
];
export const CURRENCY_INFO = {
	"PARENT_CURRENCY": "PHP",
	"CURRENT_CURRENCY": "PHP",
	"FROM_CURRENCY": "PHP",
	"TO_CURRENCY": "USD",
	"CONVERSION_RATE": 1
};
export const statusList = {
	'paid_to_dealer': "Paid to Dealer",
	'refund_received': "Refund Received",
	'disbursal_received': "Disbursal Received",
	"dealer_incentive_paid": "Dealer Incentive Paid",
	"dealer_incentive_received": "Dealer Incentive Received",
};
export const statusArr = [
	{ value: 'paid_to_dealer', label: 'Paid to Dealer', lead_source: ['finance'] },
	{ value: 'disbursal_received', label: 'Loan Value Received', lead_source: ['finance'] },
	{ value: 'refund_received', label: 'Dealer Incentive from Financier Net of Tax Received', lead_source: ['finance'] },
	{ value: 'dealer_incentive_paid', label: 'Dealer Incentive Paid', lead_source: ['refinance'] },
	{ value: 'dealer_incentive_received', label: 'Dealer Incentive Received', lead_source: ['refinance'] },
];

// manage menu Permission for Roles 
export const hasAccessPermission = (modulename, page = 'view') => {
	// const userAccess = {} // secureStorage.getItem("userAccess");
	const userAccess =  secureStorage.getItem("userAccess") || {};
	let Permission = userAccess && userAccess[modulename] && userAccess[modulename][page] && +userAccess[modulename][page] === 1 ? true : false;
	// return true//Permission;
	return Permission;
};
export const maxDATE = (from_date, d = 0) => {
	var date = new Date(from_date);
	var lastDay = new Date(date.getFullYear(), date.getMonth() + d);
	return lastDay
}
export const PRODUCT_TYPE = [
	{ name: 'UCF', value: "finance", label: 'Used Car Finance' },
	{ name: 'UCRF', value:"refinance", label: 'Used Car Refinance' },
	// { name: 'UBRF', value:"bikerefinance", label: 'Used Bike Refinance' }
];

export const FAM_ALLOWED_DOC_CATEGORY = [1, 13, 14, 60, 61, 62, 17, 64, 18, 15, 51, 16, 52, 19, 53, 54, 58];
export const UNWIND_ARRAY = function (arr, by) {
	var results = [];
	arr.forEach(function (subArray) {
		let obj = { ...subArray };
		delete obj[by];
		if (subArray[by].length) {
			subArray[by].forEach(function (item) {
				item = { [by]: item, ...obj };
				results.push(item);
			});
		}
		else {
			subArray[by] = {};
			results.push(subArray);
		}
	});
	return results;
};
export const PRODUCT_TYPE_OBJECTS = {
	"finance": 'UCF',
	"refinance": 'UCRF',
	"bikerefinance": 'UBRF'
};

export const BANK_LIST_OBJECTS = {
	"Unionbank": { acc_number: '000580021397', outbound_acc_number: '000580021397', label: 'Unionbank' },
	"Unionbank1": { acc_number: '000580021385', outbound_acc_number: '000580021385', label: 'Unionbank' },
	"BDO": { acc_number: '005688024258', outbound_acc_number: '005688024258', label: 'BDO' },
	"BDO1": { acc_number: '005688024266', outbound_acc_number: '005688024266', label: 'BDO' },
	"BPI1": { acc_number: '2841002094', outbound_acc_number: '2841002094', label: 'BPI' },
	"BPI2": { acc_number: '2841002108', outbound_acc_number: '2841002108', label: 'BPI' },
	"BDO2": { acc_number: '005688020872', outbound_acc_number: '005688020872', label: 'BDO' },
	"Unionbank2": { acc_number: '000580022043', outbound_acc_number: '000580022043', label: 'Unionbank' },
	"Unionbank3": { acc_number: '000580021440', outbound_acc_number: '000580021440', label: 'Unionbank' }
};

export const DATE_FORMAT_MY = 'MM/yyyy';

export const AMOUNT_FORMAT_TYPE = [
	{ value: 'en', label: 'Philippines Accounting Standard System' }
]

export const SPLIT_PAYMENT_TYPE_SOURCE_BASED = {
	finance: [
		{ value: "main_dealer", label: "Dealer Payment" },
		{ value: "split_dealer", label: "Split Payment" },
		{ value: "split_dealer2", label: "Split Payment2" },
		{ value: "agent_fee", label: "Agent Fee" },
		{ value: "referral_agent", label: "Referral Agent" },
		{ value: "onhold_dealer", label: "Onhold Dealer Payment" }
	],
	refinance: [
		{ value: "main_dealer", label: "Dealer/Agent Incentive" },
		{ value: "agent_fee", label: "Agent Fee" },
		{ value: "referral_agent", label: "Referral Agent" }
	],
	bikerefinance: [
		{ value: "main_dealer", label: "Dealer/Agent Incentive" },
		{ value: "agent_fee", label: "Agent Fee" },
		{ value: "referral_agent", label: "Referral Agent" }
	]
}


export const TAX_VALUE = 1.09;/// 1.08
export const TAX_PPN = 11;/// 11%

export const OTHER_PAYMENT_TRANS_TYPE = [
	{ value: 1, label: 'Interest Amount' },
	{ value: 2, label: 'Other Payment' },
	{ value: 3, label: 'Reward' },
	{ value: 4, label: 'Sweep-out Transaction' },
	{ value: 5, label: 'Wrong Transfer' },
	{ value: 6, label: 'Excess Amount' },
	{ value: 7, label: 'Top Up' },
	{ value: 8, label: 'Debit' }
];

// Radis
export const IS_REDIS = false;
export const SHOW_CAPTCHA = false  // process.env.REACT_APP_SHOW_CAPTCHA;

export const getURL2Redirect = () => {
	let url2redirct = `/outbound-report/pending/finance`
	if (hasAccessPermission('finance-report') && hasAccessPermission('fam_outbond_payments')) {
		url2redirct = `/outbound-report/pending/finance`
	}
	else if (hasAccessPermission('finance-report') && hasAccessPermission('fam_inbond_payments')) {
		url2redirct = `/inbound-report/pending/finance`
	}
	return url2redirct;
}

export const SFA_ROLE_NAME_OBJECTS = [
	{ value: '6', label: 'SO', lead_source: ['finance'] },
	{ value: '5', label: 'SM', lead_source: ['finance'] },
	{ value: '4', label: 'TH', lead_source: ['finance'] },
	{ value: '3', label: 'NHS', lead_source: ['finance'] },
];


/** This is being used in dropdown for outbound tranch update */
export const ONHOLD_PERIOD_DAYS = [
	{ label: '1 Days', value: 1 },
	{ label: '2 Days', value: 2 },
	{ label: '3 Days', value: 3 },
];

/** This is being used in dropdown for outbound tranch update */
export const AGEING_DAY_FILTER_DROPDOWN = [
	{ label: '1 Days', value: 1 },
	{ label: '2 Days', value: 2 },
	{ label: '3 Days', value: 3 },
	{ label: '4 Days', value: 4 },
	{ label: '5 Days', value: 5 },
	{ label: 'More Than 5 Days', value: 6 },
	{ label: 'Less Than a Day', value: 7 },
];

// Define an array with month names, starting index at 1
export const MONTH_NAME = [
  '', // index 0 is unused
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
];

export const AGEING_TYPE_FILTER_DROPDOWN = [
	{ value: 'disbursal', label: 'Ageing Days based on Loan Amount' },
	{ value: 'refund', label: 'Ageing Days based on Dealer Incentive' }
];

export const COUNTRY_CODE = 'ph';

